@import '../../utils/style-variables.scss';

.container {
  margin-right: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include up-to-desktop {
    margin-right: 40px;
  }

  @include up-to-tablet {
    margin-right: 0;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.message {
  @include up-to-tablet {
    max-width: 415px;
  }
}

.direct {
  margin-top: auto;

  .option {
    margin-top: 5px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 8px 15px;
    border: 1px solid transparent;
    cursor: pointer;
    color: white;
    text-decoration: none;

    @include up-to-tablet {
      padding: 6px 12px;
    }

    @media screen and (max-width: 350px) {
      padding: 4px 8px;
    }

    &:hover {
      background: rgba(#1a3e81, 0.1);
      border: 1px solid rgba(#1a3e81, 0.7);
    }

    .icon {
      display: flex;
      color: #1a3e81;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    .text {
      margin-left: 15px;
      font-size: 14px;

      @include up-to-tablet {
        margin-left: 10px;
      }

      @media screen and (max-width: 350px) {
        font-size: 12px;
      }
    }
  }
}
