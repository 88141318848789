.container {
  background: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.controls {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0;
}

.image {
  min-height: 0;
  flex-grow: 1;
  padding: 20px;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
    height: 100%;
    border: 1px solid rgb(122, 122, 122);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media screen and (orientation: portrait) {
      height: 70%;
    }
  }
}

.button {
  padding: 5px;
  background: rgb(216, 216, 216);
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
}

.button:first-of-type {
  margin-right: auto;
}

.button + .button {
  margin-left: 10px;
}
