@import '../utils/style-variables.scss';

.container {
  background: white;
  margin-top: 60px;
  border-radius: 10px;
  border: 1px solid #f1f1f3;
  padding: 40px 60px;

  @include up-to-desktop {
    padding: 30px 40px;
  }

  @include up-to-tablet {
    padding: 20px 20px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;

  @include up-to-desktop {
    margin-bottom: 20px;
  }

  @include up-to-tablet {
    margin-bottom: 15px;
  }
}

.services {
  display: flex;
  justify-content: space-between;

  @include up-to-tablet {
    flex-direction: column;
  }
}

.service {
  display: flex;
  align-items: center;

  & + .service {
    margin-left: 20px;
  }

  @include up-to-tablet {
    & + .service {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  img {
    width: 40px;

    @include up-to-desktop {
      width: 30px;
    }

    @include up-to-tablet {
      width: 30px;
    }
  }

  .text {
    margin-left: 20px;
    font-size: 20px;

    @include up-to-desktop {
      margin-left: 15px;
      font-size: 18px;
    }

    @include up-to-tablet {
      margin-left: 15px;
      font-size: 16px;
    }
  }
}
