.container {
  background: white;
  border-radius: 10px;
  color: black;
  padding: 20px;
  position: relative;
}

.error {
  background: #e60000;
  color: white;
  padding: 8px 16px;
  border-radius: 2px;
  font-size: 14px;
  margin-top: 10px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .text {
    margin-top: 20px;
  }

  .tick {
    color: green;
    width: 80px;
    height: 80px;
  }

  .subtext {
    font-size: 14px;
    opacity: 0.7;
    margin-top: 5px;
  }
}

.isDisabled {
  opacity: 0;
  pointer-events: none;
}

.field {
  display: flex;
  flex-direction: column;

  & + .field {
    margin-top: 15px;
  }

  label {
    font-size: 14px;
    padding-bottom: 4px;
  }

  input,
  textarea {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }

  input {
    line-height: 30px;
    padding: 0 8px;
  }

  textarea {
    min-height: 100px;
    width: 100% !important;
    padding: 8px;
    box-sizing: border-box;
  }
}

.submit {
  display: flex;
  justify-content: flex-end;

  button {
    margin-top: 20px;
    border-radius: 3px;
    background: #1a3e81;
    color: white;
    border: none;
    padding: 8px 20px;
    font-family: inherit;
  }
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #1a3e81;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1a3e81 transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
