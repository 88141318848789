@import '../../utils/style-variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    background: #f6fbff;
    box-shadow: 0px 0px 20px 20px #f6fbff;
  }
}

.description {
  width: 300px;
  font-size: 30px;

  @include up-to-tablet {
    width: 100%;
  }
}

.button {
  background: #48bf84;
  margin-top: 30px;
  color: white;
  border: none;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: bold;
  transform: scale(1);
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.025);
  }

  @include up-to-tablet {
    width: 100%;
  }
}
