.container {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #000000;
  height: 30px;

  & > span {
    color: white;
    font-size: 11px;
    font-weight: bold;
    padding-bottom: 3px;
  }
}
