@import '../utils/style-variables.scss';

.container {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #f1f1f3;
  padding: 0 40px;

  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  flex-direction: column;

  .image {
    background: #219ebc;
    height: 40px;
    width: 80px;
  }

  .text {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 2px;
    font-size: 12px;
    align-self: flex-end;
  }
}

.links {
  display: flex;
  margin-left: 15px;

  button {
    color: black;
    padding: 0;
    border: none;
    background: none;
    margin-left: 15px;
    font-weight: bold;
    text-decoration: none;
    font-size: 16px;
  }
}

.divider {
  flex-grow: 1;
  height: 1px;
  background: black;
  margin: 0 60px;
  opacity: 0.1;
}

.contact {
  margin-left: auto;
  color: #219ebc;

  a {
    color: inherit;
    text-decoration: none;
  }

  .option {
    display: flex;
    margin: 8px 0;
    align-items: center;
  }

  svg {
    width: 14px;
    height: 14px;
  }

  .text {
    font-weight: bold;
    font-size: 12px;
    margin-left: 4px;
  }
}

@include up-to-tablet {
  .links {
    margin-left: auto;
  }
  .divider,
  .contact {
    display: none;
  }
}
