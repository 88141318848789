@import '../../utils/style-variables.scss';

.container {
  flex-grow: 1;
  margin-left: 80px;
  box-shadow: 0px 0px 20px 20px #f6fbff;

  @include up-to-tablet {
    margin-left: 0;
    margin-top: 30px;
  }
}

.image {
  aspect-ratio: 16 / 10;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid black;
  border-radius: 2px;
}

.highlight {
  display: flex;

  .image {
    width: 100%;
  }
}

.others {
  display: flex;
  margin-top: 8px;

  .image {
    flex: 1;

    & + .image {
      margin-left: 8px;
    }
  }
}
