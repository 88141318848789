.container {
  background-image: radial-gradient(#b1b1b1 5%, transparent 5%);
  background-color: #f6fbff;
  background-position: 50% 18px;
  background-size: 40px 40px;
  box-shadow: inset 0px 0px 30px 30px #f6fbff;
}

.content {
  padding: 60px 60px 120px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 970px) {
    padding: 40px 30px 60px;
  }
}
