@import '../../utils/style-variables.scss';

.container {
  background: #219ebc;
  margin-top: 60px;
  border-radius: 10px;
  color: white;
  display: flex;
  padding: 40px 60px;

  @include up-to-desktop {
    padding: 30px 40px;
  }

  @include up-to-tablet {
    flex-direction: column;
    padding: 20px 20px;
  }
}

.half {
  flex: 1;
  width: 50%;

  @include up-to-tablet {
    width: 100%;

    & + .half {
      margin-top: 30px;
    }
  }
}
